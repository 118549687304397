'use strict';

var processInclude = require('base/util');

var now = new Date();
var $year = now.getFullYear() - 18;
var $month = '' + now.getMonth();
var $date = '' + now.getDate();

if ($month.length < 2) {
    $month = '0' + $month;
}
if ($date.length < 2) {
    $date = '0' + $date;
}
var eighteenYears = [$year, $month, $date].join('-');

$(document).ready(function () {
    processInclude(require('./login/login'));
    processInclude(require('./login/loginRegisterOTP'));
    processInclude(require('./components/enoc.js'));
    processInclude(require('./components/radioButtons'));
    processInclude(require('./components/passwordshow'));
    processInclude(require('./components/jquery.datetextentry'));

    $('#birthday').datetextentry({
        field_width_day: 25,
        field_width_month: 35,
        field_width_year: 45,
        show_errors: false,
        max_date: eighteenYears,
        max_date_message: resources.E_MAX_DATE,
        show_tooltips: false,
        on_error: function (msg) {
            if (msg !== '') {
                $('label.date-label').addClass('invalid').text(msg);
                $('label.date-label').closest('.input-field').addClass('in-valid');
                $('form#detailsEdit button.mobile-button').addClass('is-disabled');
            } else {
                var datafiled = $('label.date-label').data('label');
                $('label.date-label').removeClass('invalid').text(datafiled);
                $('label.date-label').closest('.input-field').removeClass('in-valid');

                if ($('#nationality').length) {
                    if ($('#nationality').val() !== '' && $('.jq-dte input.valid').length === 3) {
                        if ($('#genderField').length) {
                            if ($('#genderField').val() !== '') {
                                $('form#detailsEdit button.mobile-button').removeClass('is-disabled');
                            } else {
                                $('form#detailsEdit button.mobile-button').addClass('is-disabled');
                            }
                        } else {
                            $('form#detailsEdit button.mobile-button').removeClass('is-disabled');
                        }
                    } else {
                        $('form#detailsEdit button.mobile-button').addClass('is-disabled');
                    }
                } else if ($('#genderField').length) {
                    if ($('#genderField').val() !== '' && $('.jq-dte input.valid').length === 3) {
                        if ($('#nationality').length) {
                            if ($('#nationality').val() !== '') {
                                $('form#detailsEdit button.mobile-button').removeClass('is-disabled');
                            } else {
                                $('form#detailsEdit button.mobile-button').addClass('is-disabled');
                            }
                        } else {
                            $('form#detailsEdit button.mobile-button').removeClass('is-disabled');
                        }
                    } else {
                        $('form#detailsEdit button.mobile-button').addClass('is-disabled');
                    }
                } else {
                    $('form#detailsEdit button.mobile-button').removeClass('is-disabled');
                }
            }
        },
        E_BAD_DAY_FOR_MONTH: resources.E_BAD_DAY_FOR_MONTH,
        E_DAY_NAN: resources.E_DAY_NAN,
        E_DAY_TOO_BIG: resources.E_DAY_TOO_BIG,
        E_DAY_TOO_SMALL: resources.E_DAY_TOO_SMALL,
        E_MAX_DATE: resources.E_MAX_DATE,
        E_MIN_DATE: resources.E_MIN_DATE,
        E_MONTH_NAN: resources.E_MONTH_NAN,
        E_MONTH_TOO_BIG: resources.E_MONTH_TOO_BIG,
        E_MONTH_TOO_SMALL: resources.E_MONTH_TOO_SMALL,
        E_REQUIRED_FIELD: resources.E_REQUIRED_FIELD,
        E_YEAR_LENGTH: resources.E_YEAR_LENGTH,
        E_YEAR_NAN: resources.E_YEAR_NAN,
        E_YEAR_TOO_BIG: resources.E_YEAR_TOO_BIG,
        E_YEAR_TOO_SMALL: resources.E_YEAR_TOO_SMALL
    });

    if ($('#nationality').length > 0) {
        $('#nationality').select2({
            placeholder: resources.pleaseSelect,
            language: {
                noResults: function (params) {
                    return resources.labelNoCountry;
                }
            }
        });

        $('#nationality').one('select2:open', function (e) {
            $('input.select2-search__field').prop('placeholder', resources.labelSearch);
        });
    }

    $(document).on('click', '.js-otp-method a', function(e) {
        e.preventDefault();

        var $this = $(this);
        var $method = $this.data('method');
        var $parent = $this.closest('.js-otp-method');

        $this.parent().addClass('active').siblings().removeClass('active');
        $parent.find('.js-otp-method-input').val($method);
        $(document).trigger('otp:method-init');
    })
});
