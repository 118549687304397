/* eslint-disable no-undef */
'use strict';

var toastr = require('../components/toastr');
var createErrorNotification = require('base/components/errorNotification');
var errorLabel = require('../errorLabels');
const { set } = require('lodash');
var isMobile = !($(window).width() >= 768);
var globalRegister = false;

module.exports = {
    loginOTP: function () {
        $('#login-registration-otp').validate();

        $('#login-registration-otp .js-phone-mobile').rules('add', {
            required: true,
            maxlength: 12,
            minlength: 5,
            number: true,
            messages: {
                required: resources.Gloabl$mobileInvalid,
                minlength: resources.Gloabl$mobileMinMax,
                maxlength: resources.Gloabl$mobileMinMax,
                regex: resources.Gloabl$mobileInvalid
            }
        });

        module.exports.loginOTPEnableButton('#login-registration-otp', '#login-registration-otp .js-verify-phone-btn-otp');
    },

    registerOTP: function () {
        $('#registeration-form-otp').validate();

        $('#registeration-form-otp').submit(function (e) {
            globalRegister = true;
            var form = $(this);
            var gTagData;
            e.preventDefault();
            var url = form.attr('action');

            $('form.registration').trigger('login:register', e);
            var countryCode = form.find('#countrycode option:selected').val();
            if ($('input#agreeTerms').is(':checked')) {
                form.spinner().start();
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize() + '&isMobile=' + isMobile,
                    success: function (data) {
                        gTagData = {
                            firstname: $(form).find('#registration-form-fname')[0].value,
                            lastname: $(form).find('#registration-form-lname')[0].value,
                            countryCode: countryCode !== '' ? countryCode : $('.mobileNmuber .custom-select span:first').text(),
                            phone: $(form).find('.js-phone-mobile')[0].value
                        };
                        if (!data.success) {
                            form.spinner().stop();
                            gTagData.error = JSON.stringify(data.fields);
                            $(document).trigger('register:error', gTagData);
                            toastr(data, data.fields);
                        } else {
                            $(document).trigger('register:success', gTagData);
                            if (typeof data.isMiniRegister !== 'undefined' && data.isMiniRegister) {
                                var $parentRegisterBanner = $('.registerSuccessPop');
                                if (data.promoRegisterIpointsEnable && data.promoRegisterIpointsValues > 0) {
                                    $parentRegisterBanner.find('.js-ipoints-promo-text').text(data.promoMessage);
                                    $parentRegisterBanner.find('.js-ipoints-promo-text').show();
                                } else {
                                    $parentRegisterBanner.find('.js-ipoints-promo-text').hide();
                                }
                                // js-ipoints-promo-text

                                $('#registeration-step-1, #registeration-step-2, .mini-registration-header, .nomargi-flex').fadeOut(100, function () {
                                    $parentRegisterBanner.fadeIn(100);
                                });

                                $parentRegisterBanner.find('.js-register-redirect').attr('href', data.redirectUrl);
                                // $parentRegisterBanner.show();
                                form.spinner().stop();
                                // hnadle braze events
                                // braze.registerUserOnBrazeMiniPopup(data, isMobile);
                            } else {
                                // eslint-disable-next-line no-lonely-if
                                if ($('#registeration-step-3').length > 0) {
                                    $('#registeration-step-1, #registeration-step-2, .new-login-tabs').fadeOut(100, function () {
                                        $('#registeration-step-3').addClass('show');
                                    });
                                    form.spinner().stop();
                                } else {
                                    location.href = data.redirectUrl;
                                }
                            }
                        }
                        globalRegister = false;
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                        }
                        globalRegister = false;
                        form.spinner().stop();
                    }
                });
            } else {
                alert(resources.Gloabl$agreeTerms);
            }

            return false;
        });

        $('#registeration-form-otp #registration-form-fname').rules('add', {
            required: true,
            onlySpace: '#registration-form-fname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                required: resources.Global$firstnameRequired,
                minlength: resources.Global$firstnameMin,
                regex: resources.Global$invalidName,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#registeration-form-otp #registration-form-lname').rules('add', {
            required: true,
            onlySpace: '#registration-form-lname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                regex: resources.Global$invalidName,
                minlength: resources.Global$lastnameMin,
                required: resources.Gloabl$lastnameRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#registeration-form-otp .js-phone-mobile').rules('add', {
            required: true,
            maxlength: 12,
            minlength: 5,
            number: true,
            messages: {
                required: resources.Gloabl$mobileInvalid,
                minlength: resources.Gloabl$mobileMinMax,
                maxlength: resources.Gloabl$mobileMinMax,
                regex: resources.Gloabl$mobileInvalid
            }
        });

        module.exports.registerationButtonEnableMobileOTP('#registeration-form-otp', '#registeration-form-otp #mobile-register-button');
    },

    verifyClaim: function () {
        $('#verify-otp').validate();
    
        $('#verify-otp #registration-form-fname').rules('add', {
            required: true,
            onlySpace: '#registration-form-fname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                required: resources.Global$firstnameRequired,
                minlength: resources.Global$firstnameMin,
                regex: resources.Global$invalidName,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#verify-otp #registration-form-lname').rules('add', {
            required: true,
            onlySpace: '#registration-form-lname',
            regex: errorLabel.name$regex,
            minlength: 2,
            messages: {
                regex: resources.Global$invalidName,
                minlength: resources.Global$lastnameMin,
                required: resources.Gloabl$lastnameRequired,
                onlySpace: resources.Global$nofirstspace
            }
        });

        $('#verify-otp #registration-form-email').rules('add', {
            required: true,
            email: false,
            regex: errorLabel.email$regex,
            messages: {
                required: resources.Global$emailRequired,
                regex: resources.Global$invalidEmail
            }
        });

        $('#verify-otp #registration-form-new-number').rules('add', {
            required: true,
            maxlength: 13,
            minlength: 8,
            number: true,
            messages: {
                required: resources.Gloabl$mobileInvalid,
                minlength: resources.Gloabl$mobileMinMax,
                maxlength: resources.Gloabl$mobileMinMax,
                regex: resources.Gloabl$mobileInvalid
            }
        });

        $('#verify-otp').submit(function (e) {
            e.preventDefault();
            var $form = $(this);
            $.ajax({
                url: $form.attr('action'),
                type: 'post',
                dataType: 'json',
                data: $form.serialize() + '&isMobile=' + isMobile,
                success: function (data) {
                    if (!data.success) {
                        // eslint-disable-next-line no-alert
                        alert(data.message);
                    } else {
                        window.location.href = data.redirectUrl;
                    }
                }
            });
        });
    },

    verifyClaimBtnEnable: function () {
        var elemForm = '#verify-otp';
        var elemFirst = '#verify-otp #registration-form-fname';
        var elemLast = '#verify-otp #registration-form-lname';
        var elemNumber = '#verify-otp #registration-form-new-number';
        var elemEmail = '#verify-otp #registration-form-email';
        var elemButton = '#verify-otp #js-form-submit';
    
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            if (($(elemFirst).val().length >= 2 && $(elemFirst).valid()) && ($(elemLast).val().length >= 2 && $(elemLast).valid()) && $(elemEmail).valid() && $('#agreeTerms').prop('checked') === true) {
                if (!$('.js-check-new-number').prop('checked')) {
                    if ($(elemNumber).val().length >= 8) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', true);
                        $(elemButton).addClass('is-disabled');
                    }  
                } else {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                }
                
            } else {
                $(elemButton).prop('disabled', true);
                $(elemButton).addClass('is-disabled');
            }
        });
    
        $(elemForm).on('change', '#agreeTerms', function () {
           
            if (($(elemFirst).val().length >= 2 && $(elemFirst).valid()) && ($(elemLast).val().length >= 2 && $(elemLast).valid()) && $(elemEmail).valid() && $('#agreeTerms').prop('checked') === true) {
                if (!$('.js-check-new-number').prop('checked')) {
                    if ($(elemNumber).val().length >= 8) {
                        $(elemButton).prop('disabled', false).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', true).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', false).removeClass('is-disabled');
                }
                
            } else {
                $(elemButton).prop('disabled', true).addClass('is-disabled');
            }
        });

        $(elemForm).on('change', '.js-check-new-number', function () {
            $('.js-change-number').toggle();
            $('#registration-form-new-number').val('');
            if (($(elemFirst).val().length >= 2 && $(elemFirst).valid()) && ($(elemLast).val().length >= 2 && $(elemLast).valid()) && $(elemEmail).valid() && $('#agreeTerms').prop('checked') === true) {
                if (!$(this).prop('checked')) {
                    
                    if ($(elemNumber).val().length >= 8) {
                        $(elemButton).prop('disabled', false).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', true).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', false).removeClass('is-disabled');
                }
                
            } else {
                $(elemButton).prop('disabled', true).addClass('is-disabled');
            }
        });
    },

    loginOTPEnableButton: function (elemForm, elemButton) {
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(elemForm).valid()) {
                    $(elemButton).prop('disabled', false);
                    $(elemButton).removeClass('is-disabled');
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            });
        });
    },

    registerationButtonEnableMobileOTP: function (elemForm, elemButton) {
        var $flagged = false;
        $(elemForm).on('keyup', 'input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)', function (event) {
            $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                if ($(this).val() >= 2 && $('#agreeTerms').prop('checked') === true) {
                    if ($(elemForm).valid()) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                    }
                } else {
                    $(elemButton).prop('disabled', 'disabled');
                    $(elemButton).addClass('is-disabled');
                }
            });
        });

        $(elemForm).on('change', '#agreeTerms', function () {
            if ($('#agreeTerms').prop('checked') === true) {
                $(elemForm).find('input:not(:button):not(:checkbox):not(:hidden):not(#invitation_code)').each(function () {
                    if ($(elemForm).valid()) {
                        $(elemButton).prop('disabled', false);
                        $(elemButton).removeClass('is-disabled');
                    } else {
                        $(elemButton).prop('disabled', 'disabled');
                        $(elemButton).addClass('is-disabled');
                    }
                });
            } else {
                $(elemButton).prop('disabled', 'disabled');
                $(elemButton).addClass('is-disabled');
            }
        });
    },

    mobileOtpFill: function () {
        $('.singleOtp').first().focus();
        // eslint-disable-next-line consistent-return
        $('.singleOtp').keyup(function () {
            var len = $(this).val().toString().length;
            var act = $(this).parent().find('.combineOtp');
            var accumulatedValue = '';

            if (len === 1) {
                $(this).next().focus();
            }

            // eslint-disable-next-line consistent-return
            $('.singleOtp').each(function () {
                var $trim = $(this).val();
                $trim = $.trim($trim);
                accumulatedValue += $trim;
                if ($trim.length !== 0 && accumulatedValue.length === 4) {
                    act.val(accumulatedValue);
                    if (globalRegister === false) {
                        if ($('#registeration-form-otp').length > 0) {
                            $('#registeration-form-otp').submit();
                        }
                        if ($('#partner-register').length > 0) {
                            $('#partner-register').submit();
                        }
                    }
                    return false;
                }
            });
        });
    },

    resendSMSRegister: function() {
        $('.resendSmsRegister').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            var $parent =  $this.parent();
            $parent.find('.resendSmsRegister').addClass('disabled');
            $parent.find('.timerText').show();
            var gTagData;
            var url = $this.data('verification-url');
            var $form = $('#registeration-form-otp');
            var countryCode = $form.find('#countrycode').val();
            var phoneValue = $form.find('.js-phone-mobile').val();
            var $methodDOM = $form.find('.js-otp-method').find('li.active');
            var mode = 'whatsapp';
            $form.spinner().start();
            if ($methodDOM.length > 0) {
                mode = $methodDOM.find('a').data('method');
            }
            var phoneNumber = countryCode + phoneValue;
    
            var data = {
                phoneNumber: phoneNumber,
                // email: email,
                countryCode: countryCode,
                mode: mode
            };
    
            var csrfField = $('.js-csrf');
            data[csrfField.attr('name')] = csrfField.val();
    
            gTagData = {
                phoneNumber: phoneNumber
            };
    
            // eslint-disable-next-line no-undef
            grecaptcha.ready(function () {
                // eslint-disable-next-line no-undef
                grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                    data.token = token;
                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: data,
                        success: function (response) {
                            $form.spinner().stop();
                            gTagData = {
                                phoneNumber: phoneNumber,
                                message: response.message,
                                countryCode: countryCode,
                                phone: phoneValue
                            };
                            if (!response.success) {
                                // eslint-disable-next-line no-unused-expressions, no-alert
                                isMobile ? alert(response.message) : toastr.error(response.message);
                                $(document).trigger('verification:error', gTagData);
                                return;
                            }
                            $(document).trigger('verification:success', gTagData);
    
                            var timer = $this.data('enableforseconds');
    
                            var counter = timer;
                            var interval = setInterval(function () {
                                counter--;
                                // Display 'counter' wherever you want to display it.
                                if (counter <= 0) {
                                    clearInterval(interval);
                                    $parent.find('.resendSmsRegister').removeClass('disabled');
                                    $parent.find('.timerText').hide();
                                    $parent.find('.timerText span').html(timer);
                                    return;
                                    // eslint-disable-next-line no-else-return
                                } else {
                                    $parent.find('.timerText span').text(counter);
                                }
                            }, 1000);
                            $form.spinner().stop();
                            // var token = response.token;
                            // $('.otp-token').val(token);
                            $('.js-btn-save').removeAttr('disabled').removeClass('is-disabled');
                        },
                        error: function (error) {
                            $('.js-register-btn').prop('disabled', false);
                            $('.js-register-btn').removeClass('is-disabled');
                            // eslint-disable-next-line no-unused-expressions, no-alert
                            isMobile ? alert(error) : toastr.error(error);
                            $form.spinner().stop();
                        }
                    });
                });
            });
        });
    },

    init: function () {
        $('.js-verify-phone-btn-otp').on('click', function (e) {
            e.preventDefault();
            var $form = $('#login-registration-otp');
            $form.find('.resendSms').addClass('disabled');
            $form.find('.timerText').show();
            var gTagData;
            var url = $(this).parents('#login-registration-otp').attr('action');
            // var $form = $('#registeration-form');
            var countryCode = $form.find('#countrycode').val();
            // var email = $('#registration-form-email').val();
            var phoneValue = $form.find('.js-phone-mobile').val();
            var $methodDOM = $form.find('.js-otp-method').find('li.active');
            var mode = 'whatsapp';
            $form.spinner().start();
            if ($methodDOM.length > 0) {
                mode = $methodDOM.find('a').data('method');
            }
            var phoneNumber = countryCode + phoneValue;
    
            var data = {
                phoneNumber: phoneValue,
                // email: email,
                countryCode: countryCode,
                mode: mode
            };
            if ($(this).hasClass('resendSms')) {
                data = {
                    phoneNumber: phoneNumber
                };
            }
            var csrfField = $('.js-csrf');
            data[csrfField.attr('name')] = csrfField.val();
            gTagData = {
                phoneNumber: phoneNumber
            };
     
            // eslint-disable-next-line no-undef
            grecaptcha.ready(function () {
                // eslint-disable-next-line no-undef
                grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                    data.token = token;
                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: data,
                        success: function (response) {
                            $form.spinner().stop();
                            gTagData = {
                                phoneNumber: phoneNumber,
                                message: response.message,
                                countryCode: countryCode,
                                phone: phoneValue
                            };
                            if (!response.success) {
                                // eslint-disable-next-line no-unused-expressions, no-alert
                                isMobile ? alert(response.message) : toastr(response, response.error);
                                $(document).trigger('verification:errorLogin', gTagData);
                                return;
                            }
                            $(document).trigger('verification:successLogin', gTagData);
    
                            $form.find('#registeration-step-1').fadeOut(50, function () {
                                var $num = $('.js-phone-mobile').val();
                                var $code = $('#countrycode').val();
                                $('.new-login-tabs').fadeOut();
                                $form.find('.js-add-number').text($code + $num);
                                $form.find('.js-otp-text').text(response.message);
                                $form.find('#registeration-step-2').fadeIn(100);
                                $('.loginBox').hide();
                                $('.nomargi-flex .js-registeration-goback-otp').show();
                                window.scrollTo(0, 0);
                                var timer = $('.resendSms').data('enableforseconds');
    
                                var counter = timer;
                                var interval = setInterval(function () {
                                    counter--;
                                    // Display 'counter' wherever you want to display it.
                                    if (counter <= 0) {
                                        clearInterval(interval);
                                        $form.find('.resendSms').removeClass('disabled');
                                        $form.find('.timerText').hide();
                                        $form.find('.timerText span').html(timer);
                                        return;
                                        // eslint-disable-next-line no-else-return
                                    } else {
                                        $form.find('.timerText span').text(counter);
                                    }
                                }, 1000);
                            });
                            // $('#registeration-step-1').fadeOut(50, function () {
                            //     $('#registeration-step-2').fadeIn(100);
                            //     $('.nomargi-flex .js-registeration-goback-otp').show();
                            //     $('.otp-mobile-inputs input').val('');
                            //     $('.new-login-tabs').hide();
                            // });
                            $form.spinner().stop();
                            // var token = response.token;
                            // $('.otp-token').val(token);
                            $('.js-btn-save').removeAttr('disabled').removeClass('is-disabled');
                        },
                        error: function (error) {
                            $form.find('.js-register-btn').prop('disabled', false);
                            $form.find('.js-register-btn').removeClass('is-disabled');
                            // eslint-disable-next-line no-unused-expressions, no-alert
                            isMobile ? alert(error) : toastr(error);
                            $form.spinner().stop();
                        }
                    });
                });
            });
        });

        $('.js-login-otp').on('click', function (e) {
            e.preventDefault();
            var verificationURL = $(this).data('verification-url');
            var loginOTP = $(this).parents('#registeration-step-2').find('#registration-form-verification-code').val();
            if (loginOTP === '') {
                toastr('Please enter the otp to proceed');
                return;
            }
            var dataGtag = {
                code: loginOTP
            };
            $.ajax({
                url: verificationURL,
                type: 'POST',
                data: { otp: loginOTP },
                success: function (response) {
                    if (!response.success) {
                        toastr(response, response.error);
                        return;
                    }
                    $(document).trigger('login:success', dataGtag);
                    window.location.href = response.redirectUrl;
                },
                error: function (error) {
                    toastr(error);
                }
            });
        });

        
    }
};
