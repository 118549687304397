'use strict';


$('.js-enoc-register').on('click', function (e) {
    e.preventDefault();
    $('.enoc-login-box').removeClass('show');
    $('.enoc-register').addClass('show');
    $('.enoc-login').removeClass('show');
    $('.enoc-forgot').removeClass('show');
    $('.forgot-password-success').hide();
    $('.forgot-password').show();
});

$('.js-enoc-login').on('click', function (e) {
    e.preventDefault();
    $('.enoc-login-box').removeClass('show');
    $('.enoc-login').addClass('show');
    $('.enoc-register').removeClass('show');
    $('.enoc-forgot').removeClass('show');
    $('.forgot-password-success').hide();
    $('.forgot-password').show();
});


$('body').on('click', '.js-enoc-code', function (e) {
    e.preventDefault();
    var $this = $(this);
    var $parent = $this.closest('.enoc-code');
    var $clone = $parent.clone();
    var $wrapper = $this.closest('.js-enoc-code-wrapper');
    $parent.find('.js-enoc-code').remove();
    $clone.find('input:text').val('').end().appendTo($wrapper);
    $(document).trigger('reinit:materlialize');
});

$('body').on('click', '.js-password-help-enoc', function (e) {
    e.preventDefault();
    $('.forgot-password-success').hide();
    $('.forgot-password').show();
    $('.enoc-login-box').removeClass('show');
    $('.enoc-login').removeClass('show');
    $('.enoc-register').removeClass('show');
    $('.enoc-forgot').addClass('show');
});
