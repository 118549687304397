/* eslint-disable no-undef */
module.exports = {
    init: function () {
        $('.revealPassword').click(function () {
            var $this = $(this);
            var $parent = $this.parent();
            if ($('.instantcash-register-form').length > 0) {
                var $show = $this.data('show');
                var $hide = $this.data('hide');
                $parent.toggleClass('showPassword');
                if ($parent.hasClass('showPassword')) {
                    $parent.find('.form-control').attr('type', 'text');
                    $this.text($hide);
                } else {
                    $parent.find('.form-control').attr('type', 'password');
                    $this.text($show);
                }
            } else {
                $parent.toggleClass('showPassword');
                if ($parent.hasClass('showPassword')) {
                    $parent.find('.form-control').attr('type', 'text');
                } else {
                    $parent.find('.form-control').attr('type', 'password');
                }
            }
        });
    }
};
